import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators'
import { Mutations, Actions, MutationsActions } from '@/store/enums/StoreEnums'
import ApiService from '@/core/services/ApiService'
import { Account } from '@/store/modules/AccountModule'

export interface Country {
    name: string,
    code: string
}

export interface Company {
    companyUuid: string;
    label: string;
    country: Country;
    account: Account;
    settings: {
        allowedToDeploy: boolean,
        allowedToPublish: boolean,
        allowedTrial: boolean,
        trialStart?: string,
        trialEnd?: string,
        trialRange: string
    }
    createdAt: string;
    updatedAt: string;
}

export interface Filter {
    limit: number,
    offset?: number
    label?: string
}
export interface CompaniesPayload {
    filter: Filter
}

interface CompanyDetails {
    apiCompaniesCounter: number | null,
    companies: object[]
}

interface ListItem {
    value: string
    label: string
}

@Module
export default class CompanyModule extends VuexModule implements CompanyDetails {
    company = {} as Company
    companies = [] as Array<Company>
    errors = {};
    fetchCompaniesInProgress = false
    apiCompaniesCounter = null
    counter = 0;
    apiCompaniesCount = null

    /**
     * Get authentification errors
     * @returns array
     */
    get getCompanyErrors () {
      return this.errors
    }

    get getCompaniesAsOptions (): object[] {
      return this.companies.map((item): ListItem => {
        return { value: item.companyUuid, label: item.label }
      })
    }

    get getCompany (): Company {
      return this.company
    }

    get getCompanies () {
      return this.companies
    }

    get isGetCompaniesInProgress () {
      return this.fetchCompaniesInProgress
    }

    get getApiCompaniesCounter () {
      return this.apiCompaniesCount
    }

    @Action
    [Actions.API_CREATE_NEW_COMPANY] (companyDetails) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/companies', companyDetails)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action
    [Actions.API_UPDATE_COMPANY] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.update('api/v1/companies', payload.companyUuid, payload.companyDetails)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action
    [Actions.API_DELETE_COMPANY] (companyUuid) {
      return ApiService.delete('api/v1/companies/' + companyUuid)
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_COMPANY_ERRORS, [{ 0: response.data.error.message }])
        })
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_COMPANY_BY_UUID] (companyUuid) {
      const response = await ApiService.get('api/v1/companies', companyUuid)
      const company = response.data.payload

      return { company }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_COMPANIES_COUNT] (payload) {
      const response = await ApiService.get('api/v1/companies', 'count', payload)
      const apiCompaniesCount = response.data.payload.count

      return { apiCompaniesCount }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_COMPANIES] (payload) {
      this.context.commit(Mutations.SET_FETCH_IN_PROGRESS, true)
      // const filter = { filter: { limit: payload.limit, offset: payload.offset } }
      const response = await ApiService.get('api/v1/companies', '', payload)
      if (response.status === 200) {
        this.context.commit(Mutations.SET_FETCH_IN_PROGRESS, false)
      }
      const companies = response.data.payload

      return { companies }
    }

    @Mutation
    [Mutations.SET_FETCH_IN_PROGRESS] (isLoading) {
      this.fetchCompaniesInProgress = isLoading
    }

    @Mutation
    [Mutations.SET_COMPANY_ERRORS] (error) {
      this.errors = { ...error }
    }

    @Mutation
    [Mutations.SET_COMPANY] (newCompany) {
      this.company = newCompany
      // Object.assign(this.company, newCompany)
    }

    @Mutation
    [Mutations.FILTER_COMPANIES] (query) {
      this.companies = this.companies.filter((item) => { return query !== '' ? item.label.toLowerCase().includes(query.toLowerCase()) : item.label })
    }

    @Mutation
    [Mutations.SET_COMPANIES] (companies) {
      this.companies = companies
    }
}
