/* eslint-disable */
enum Actions {
  // action types
  ADD_BODY_CLASSNAME = 'addBodyClassName',
  REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
  ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
  REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
  ADD_CLASSNAME = 'addClassName',
  VERIFY_AUTH = 'verifyAuth',
  LOGIN = 'login',
  LOGOUT = 'logout',
  REGISTER = 'register',
  UPDATE_USER = 'updateUser',
  FORGOT_PASSWORD = 'forgotPassword',
  SET_BREADCRUMB_ACTION = 'setBreadcrumbAction',
  GET_CSRF_COOKIE = 'getCsrfCookie',
  
  // accounts
  GET_ACCOUNT_BY_UUID = 'getAccountByUuid',
  API_ACCOUNT_REQUEST_ACTIVATE = 'apiAccountRequestActivate',
  API_ACCOUNT_CHECK_ACTIVATE = 'apiAccountCheckActivate',
  API_ACCOUNT_SUBMIT_ACTIVATE = 'apiAccountSubmitActivate',
  API_GET_ACCOUNTS_BY_FILTER = 'apiGetAccountsByFilter',
  API_CREATE_NEW_ACCOUNT = 'apiCreateNewAccount',
  API_UPDATE_ACCOUNT = 'apiUpdateAccount',
  API_DELETE_ACCOUNT = 'apiDeleteAccount',
  
  // companies
  API_GET_COMPANIES_BY_FILTER = 'apiGetCompaniesByFilter',
  API_GET_COUNT_COMPANIES_BY_FILTER = 'apiGetCompaniesCountByFilter',
  API_CREATE_NEW_COMPANY = 'apiCreateNewCompany',
  API_UPDATE_COMPANY = 'apiUpdateCompany',
  API_DELETE_COMPANY = 'apiDeleteCompany',


    // OAuth 
  SET_TOKEN = 'setToken',
  CHECK_TOKEN_EXISTS = 'checkTokenExists',
  FETCH_USER = 'fetchUser',
  CLEAR_AUTHENTICATION = 'clearAuthentication',
  API_GET_LEADS_BY_FILTER = 'apiGetLeadsByFilter',
  API_GET_LEADS_COUNT_BY_FILTER = 'apiGetLeadsCountByFilter',
  API_GET_PUBLIC_SESSION = 'apiGetPublicSession',
  API_GET_ACTIVE_USER_STATISTICS_BY_FILTER = 'apiGetActiveUserStatisticsByFilter'
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
  PURGE_AUTH = 'logOut',
  SET_AUTH = 'setAuth',
  SET_USER = 'setUser',
  SET_PASSWORD = 'setPassword',
  SET_ERROR = 'setError',
  SET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
  SET_LAYOUT_CONFIG = 'setLayoutConfig',
  RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
  OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
  OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig',
  SET_ACCOUNT = 'setAccount',
  SET_ACCOUNTS = 'setAccounts',
  SET_IS_LOADING = 'setIsLoading',
  
  // accounts 
  SET_ACCOUNTS_FETCH_IN_PROGRESS = 'setAccountsFetchInProgress',
  
  // companies 
  SET_FETCH_IN_PROGRESS = 'setFetchInProgress',
  SET_COMPANY_ERRORS = 'setCompanyErrors',
  SET_COMPANY = 'setCompany',
  FILTER_COMPANIES = 'filterCompanies',
  SET_COMPANIES = 'setCompanies',

  // OAuth
  STORE_TOKEN = 'storeToken',
  SET_AUTHENTICATED = 'setAuthenticated',
  SET_ACCOUNT_DATA = 'setAccountData',
  SET_FORCE_LOGOUT = 'setForceLogout',
  SET_FILTERED_LEADS = 'setFilteredLeads',
  UPDATE_FILTERED_LEADS_SET_ACTIVE_USERS = 'updatedFilteredLeadsSetActiveUsers',
  SET_FILTERED_ACTIVE_USER_STATISTICS = 'setFilteredActiveUserStatistics'
}

enum MutationsActions {
  // accounts 
  API_GET_MUTATE_ACCOUNTS = 'apiGetMutateAccounts',
  API_GET_MUTATE_ACCOUNTS_COUNT = 'apiGetMutateAccountsCount',
  API_GET_MUTATE_ACCOUNT_BY_UUID = 'apiGetMutateAccountByUuid',
  // companies
  API_GET_MUTATE_COMPANIES_COUNT = 'apiGetMutateCompaniesCount',
  API_GET_MUTATE_COMPANIES = 'apiGetMutateCompanies',
  API_GET_MUTATE_COMPANY_BY_UUID = 'apiGetMutateCompanyByUuid',
  // metrics
  API_GET_MUTATE_METRICS_BY_FILTER = 'apiGetMutateMetricsByFilter',
  //account login histories
  API_GET_MUTATE_LOGIN_HISTORY_BY_FILTER = 'apiGetMutateLoginHistoryByFilter',
  API_GET_MUTATE_LOGIN_HISTORY_COUNT_BY_FILTER = 'apiGetMutateLoginHistoryCountByFilter'
}

export { Actions, Mutations, MutationsActions }
