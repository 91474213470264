import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators'
import { Actions, Mutations, MutationsActions } from '@/store/enums/StoreEnums'
import ApiService from '@/core/services/ApiService'

export interface Metrics {
    numAccounts? : number
    numSessions? : number
    inProgressSessions? : number
    inScheduledSessions? : number
    finishedSessions? : number
    numUniqueUsers? : number
    finishedLearningPaths? : number
}

export interface MetricsFilter {
    startDate?: string
    endDate?: string
    companies?: Array<any>
    accounts?: Array<any>
}

export interface MetricsPayload {
    metrics: Array<string>
    filter: MetricsFilter
}

export interface LoginHistoryFilter {
    companyUuid?: string|string[]
    count?: boolean
    limit?: number
    offset?: number
}

export interface LoginHistoryPayload {
    filter: LoginHistoryFilter
}

export interface LoginHistory {
    accountLoginHistoryUuid: string;
    accountUuid: string;
    createdAt: string;
}

export interface LeadInterface {
    leadUuid: string;
    requestIp: string;
    name?: string;
    email: string;
    phone?: string;
    category?: string;
    activeUsers?: object
}

export interface LeadFilter {
    limit: number,
    offset: number,
    withActiveUsers?:boolean
}

export interface LeadPayload {
    filter: LeadFilter
}

export interface UserFilter {
    limit: number,
    offset: number,
    activeUserUuids: Array<string>
}

export interface UserPayload {
    filter: UserFilter
}

@Module
export default class StatisticModule extends VuexModule {
    metrics = {} as Metrics
    loginHistories = [] as Array<LoginHistory>
    loginHistoriesCount = 0
    leadsFiltered = [] as Array<LeadInterface>
    userStatisticsFiltered = [] as any
    get getMetrics () {
      return this.metrics
    }

    get getLoginHistories () {
      return this.loginHistories
    }

    get getLoginHistoriesCount () {
      return this.loginHistoriesCount
    }

    get getLeadsFiltered () {
      return this.leadsFiltered
    }

    get getUserStatisticsFiltered () {
      return this.userStatisticsFiltered
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_METRICS_BY_FILTER] (filter) {
      const response = await ApiService.get('api/v1/statistics', '', filter)
      const metrics = response.data.payload.metrics

      return { metrics }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_LOGIN_HISTORY_BY_FILTER] (filter) {
      const response = await ApiService.get('api/v1/accountsLoginHistory', '', filter)
      const loginHistories = response.data.payload

      return { loginHistories }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_LOGIN_HISTORY_COUNT_BY_FILTER] (filter) {
      const response = await ApiService.get('api/v1/accountsLoginHistory/count', '', filter)
      const loginHistoriesCount = response.data.payload.count

      return { loginHistoriesCount }
    }

    @Mutation
    [Mutations.SET_FILTERED_LEADS] (filteredLeads) {
      this.leadsFiltered = filteredLeads
    }

    @Mutation
    [Mutations.UPDATE_FILTERED_LEADS_SET_ACTIVE_USERS] (leadUuid, activeUsers) {
      const foundLead = this.leadsFiltered.find((lead) => lead.leadUuid === leadUuid)
      if (foundLead) {
        foundLead.activeUsers = activeUsers
      }
    }

    @Mutation
    [Mutations.SET_FILTERED_ACTIVE_USER_STATISTICS] (activeUserStatistics) {
      this.userStatisticsFiltered = activeUserStatistics
    }

    @Action({ rawError: true })
    async [Actions.API_GET_LEADS_BY_FILTER] (filter) {
      return new Promise((resolve, reject) => {
        ApiService.get('api/v1/lead/get', '', filter)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_GET_LEADS_COUNT_BY_FILTER] (filter) {
      return new Promise((resolve, reject) => {
        ApiService.get('api/v1/lead/getCount', '', filter)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_GET_ACTIVE_USER_STATISTICS_BY_FILTER] (filter) {
      return new Promise((resolve, reject) => {
        ApiService.get('api/v1/active-user', 'statistics', filter)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_GET_PUBLIC_SESSION] (publicSessionUuid) {
      return new Promise((resolve, reject) => {
        ApiService.get('api/v1/public-session', publicSessionUuid, {})
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }
}
