import { isEmpty } from 'lodash'
import localforage from 'localforage'

import ApiService from '@/core/services/ApiService'
import CsrfService from '@/core/services/CsrfService'

import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators'
import { Account } from '@/store/modules/AccountModule'
import router from '@/router/clean'

export interface AccountAuthInfo {
  errors: unknown;
  account: Account | null;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements AccountAuthInfo {
  errors = {};
  account = null
  isAuthenticated
  oauthToken = null

  /**
   * Get current account object
   * @returns Account
   */
  get currentAccount (): Account |null {
    return this.account
  }

  /**
   * Verify account authentication
   * @returns boolean
   */
  get isAccountAuthenticated (): boolean {
    return this.isAuthenticated
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors () {
    return this.errors
  }

  @Mutation
  [Mutations.SET_ERROR] (error) {
    this.errors = { ...error }
  }

  @Mutation
  [Mutations.SET_AUTH] (account) {
    this.isAuthenticated = true
    this.account = account
    this.errors = {}
    CsrfService.saveCsrfAccountUuid(account.accountUuid)
  }

  @Mutation
  [Mutations.SET_USER] (account) {
    this.account = account
  }

  @Mutation
  [Mutations.SET_PASSWORD] (password) {
    // this.user.password = password
  }

  @Mutation
  [Mutations.PURGE_AUTH] () {
    this.isAuthenticated = false
    // this.account = {} as Account
    this.errors = []
    CsrfService.destroyCsrfAccountUuid()
  }

  @Action
  [Actions.GET_CSRF_COOKIE] () {
    return ApiService.get('api/csrf-cookie')
  }

  @Action({ rawError: true })
  async [Actions.LOGIN] (credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('oauth/account/login', credentials)
        .then(({ data }) => {
          this.context.dispatch(Actions.SET_TOKEN, data.payload.token.access_token).then(() => {
            this.context.dispatch(Actions.FETCH_USER)
          })
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response)
        })
    })
  }

  @Action
  [Actions.CHECK_TOKEN_EXISTS] () {
    return localforage.getItem('authToken').then((token) => {
      if (isEmpty(token)) {
        return Promise.reject(new Error('NO_STORAGE_TOKEN'))
      }
      return localforage.getItem('forceLogout').then((isForceLogout: any) => {
        if (isForceLogout === true) {
          return this.context.dispatch(Actions.CLEAR_AUTHENTICATION).then(() => {
            return Promise.reject(new Error('FORCE_LOGOUT'))
          })
        }
        return Promise.resolve(token)
      })
    })
  }

  @Action
  [Actions.SET_TOKEN] (token) {
    if (isEmpty(token)) {
      return this.context.dispatch(Actions.CHECK_TOKEN_EXISTS).then((token) => {
        ApiService.setAuthorization(token)
      })
    }

    this.context.commit(Mutations.STORE_TOKEN, token)
    ApiService.setAuthorization(token)
  }

  @Action
  [Actions.FETCH_USER] () {
    return ApiService.get('api/v1/oauth/account').then((response) => {
      this.context.commit(Mutations.SET_AUTHENTICATED, true)
      this.context.commit(Mutations.SET_ACCOUNT_DATA, response.data.payload)
    })
  }

  @Action
  [Actions.CLEAR_AUTHENTICATION] () {
    this.context.commit(Mutations.SET_AUTHENTICATED, false)
    this.context.commit(Mutations.SET_ACCOUNT_DATA, null)
    this.context.commit(Mutations.STORE_TOKEN, null)
    this.context.commit(Mutations.SET_FORCE_LOGOUT, false)
    ApiService.setAuthorization(null)
  }

  @Mutation
  [Mutations.SET_FORCE_LOGOUT] (forceLogout) {
    localforage.setItem('forceLogout', forceLogout).then(() => {
      localforage.getItem('intended').then((name: any) => {
        if (isEmpty(name)) {
          router.replace({ name: 'companies' })
          return
        }
        router.replace({ name: name })
      })
    })
  }

  @Mutation
  [Mutations.STORE_TOKEN] (token) {
    if (isEmpty(token)) {
      // admin token
      localforage.removeItem('authToken', token)
      return
    }

    // Check empty remove token
    localforage.setItem('authToken', token)
  }

  @Mutation
  [Mutations.SET_AUTHENTICATED] (isAuthenticated) {
    this.isAuthenticated = isAuthenticated
  }

  @Mutation
  [Mutations.SET_ACCOUNT_DATA] (accountData) {
    this.account = accountData
  }

  @Action
  [Actions.LOGOUT] () {
    return ApiService.get('api/v1/oauth/account/logout').then(() => {
      this.context.dispatch(Actions.CLEAR_AUTHENTICATION).then()
    })
  }

  @Action
  [Actions.REGISTER] (credentials) {
    return ApiService.post('register', credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors)
      })
  }

  @Action
  [Actions.FORGOT_PASSWORD] (payload) {
    return ApiService.post('forgot_password', payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {})
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors)
      })
  }

  @Action
  [Actions.VERIFY_AUTH] () {
    if (!CsrfService.getCsrfAccountUuid()) {
      this.context.commit(Mutations.PURGE_AUTH)
      return
    }

    return ApiService.get('api/csrf-check')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data.payload)
      })
      .catch(({ response }) => {
        if (response.status === 401) {
          // when the cookie expire redirect to sign-in
          this.context.commit(Mutations.PURGE_AUTH)
          router.push('/sign-in')
        }
      })
  }
}
