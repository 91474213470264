import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators'
import { Mutations, Actions, MutationsActions } from '@/store/enums/StoreEnums'
import ApiService from '@/core/services/ApiService'

export interface Account {
    accountUuid: string;
    type?: string | number | undefined;
    email: string;
    firstName: string;
    lastName: string;
    fullName: string;
    phone?: string;
    roleId: number | string;
    company?: {
        uuid: string,
        name: string
    };
    activatedAt?: string;
    createdAt: string;
    updatedAt: string;
}

export interface AccountFilter {
    limit: number,
    offset: number,
    firstName?: string,
    lastName?: string,
    email?: string
}

export interface AccountsPayload {
    filter: AccountFilter
}

@Module
export default class AccountModule extends VuexModule {
    account = {} as Account
    accounts = []
    fetchAccountsInProgress = false
    apiAccountsCount = null
    loading = false

    get getAccounts () {
      return this.accounts
    }

    get isGetAccountsInProgress (): Boolean {
      return this.fetchAccountsInProgress
    }

    get getApiAccountsCount (): Number {
      return this.apiAccountsCount as unknown as Number
    }

    get getAccount (): Account {
      return this.account
    }

    get isLoading () {
      return this.loading
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_ACCOUNTS] (payload) {
      this.context.commit(Mutations.SET_ACCOUNTS_FETCH_IN_PROGRESS, true)
      const response = await ApiService.get('api/v1/accounts', '', payload)
      if (response.status === 200) {
        this.context.commit(Mutations.SET_ACCOUNTS_FETCH_IN_PROGRESS, false)
      }
      const accounts = response.data.payload

      return { accounts }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_ACCOUNTS_COUNT] (payload) {
      const response = await ApiService.get('api/v1/accounts', 'count', payload)
      const apiAccountsCount = response.data.payload.count

      return { apiAccountsCount }
    }

    @Mutation
    [Mutations.SET_ACCOUNTS_FETCH_IN_PROGRESS] (isLoading: boolean) {
      this.fetchAccountsInProgress = isLoading
    }

    @Action({ rawError: true })
    [Actions.API_CREATE_NEW_ACCOUNT] (accountDetails) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/accounts', accountDetails)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action
    [Actions.API_UPDATE_ACCOUNT] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.update('api/v1/accounts', payload.accountUuid, payload.accountDetails)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action
    [Actions.GET_ACCOUNT_BY_UUID] (payload) {
      return ApiService.get('api/v1/account', 'getByUuid', { accountUuid: payload.uuid })
        .then(({ data }) => {
          console.log(data)
        })
        .catch(({ response }) => {
          if (response.status === 404 || response.status === 422 || response.status === 400 || response.status === 500) {
            this.context.commit(Mutations.SET_ERROR, [{ 0: 'Could not sign in.' }])
          } else {
            this.context.commit(Mutations.SET_ERROR, [{ 0: response.data.error.message }])
          }
        })
    }

    @Action
    [Actions.API_GET_ACCOUNTS_BY_FILTER] (payload) {
      this.context.commit(Mutations.SET_IS_LOADING, true)
      const filter = { filter: { limit: payload.limit, offset: payload.offset } }
      return ApiService.get('api/v1/accounts', '', filter)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ACCOUNTS, data.payload)
          this.context.commit(Mutations.SET_IS_LOADING, false)
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_IS_LOADING, false)
          if (response.status === 404 || response.status === 422 || response.status === 400 || response.status === 500) {
            this.context.commit(Mutations.SET_ERROR, [{ 0: 'Could not sign in.' }])
          } else {
            this.context.commit(Mutations.SET_ERROR, [{ 0: response.data.error.message }])
          }
        })
    }

    @Action
    [Actions.API_ACCOUNT_REQUEST_ACTIVATE] (payload) {
      return ApiService.post('api/v1/account/validation/request', payload)
        .then(({ data }) => {
          console.log('success', data)
          this.context.commit(Mutations.SET_ERROR, {})
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [{ 0: response.data.error.message }])
        })
    }

    @Action
    [Actions.API_ACCOUNT_CHECK_ACTIVATE] (payload) {
      return ApiService.post('api/v1/account/validation/check', payload)
        .then(({ data }) => {
          console.log('data ', data)
          return true
        })
        .catch(({ response }) => {
          console.log('response', response)
          return false
        })
    }

    @Action
    [Actions.API_ACCOUNT_SUBMIT_ACTIVATE] (credentials) {
      return ApiService.post('api/v1/account/validation/submit', credentials)
        .then(({ data }) => {

        })
        .catch(({ response }) => {
          console.log(response.data)
          this.context.commit(Mutations.SET_ERROR, [{ 0: response.data.error.message }])
        })
    }

    @Action
    [Actions.API_DELETE_ACCOUNT] (accountUuid) {
      return ApiService.delete('api/v1/accounts/' + accountUuid)
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_COMPANY_ERRORS, [{ 0: response.data.error.message }])
        })
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_ACCOUNT_BY_UUID] (accountUuid) {
      const response = await ApiService.get('api/v1/accounts', accountUuid)
      const account = response.data.payload

      return { account }
    }

    @Mutation
    [Mutations.SET_ACCOUNT] (account) {
      this.account = account
    }

    @Mutation
    [Mutations.SET_ACCOUNTS] (accounts) {
      Object.assign(this.accounts, accounts)
    }

    @Mutation
    [Mutations.SET_IS_LOADING] (isLoading) {
      this.loading = isLoading
    }
}
