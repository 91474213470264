import { createApp } from 'vue'
import App from './App.vue'
import localforage from 'localforage'

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from './router/clean'
import store from './store'
import ElementPlus from 'element-plus'
import i18n from '@/core/plugins/i18n'

// imports for app initialization
import ApiService from '@/core/services/ApiService'
import { initApexCharts } from '@/core/plugins/apexcharts'
import { initInlineSvg } from '@/core/plugins/inline-svg'
import { initVeeValidate } from '@/core/plugins/vee-validate'

import '@/core/plugins/prismjs'
import 'bootstrap'
import { Actions } from '@/store/enums/StoreEnums'

/**
 * Local forage main configuration.
 */
localforage.config({
  name: 'teamlearning',
  storeName: 'store'
})
const app = createApp(App)
/**
 * Check the user token is exist or not, depends on that we redirect the user
 * to the requested page or if the page needs authentication redirect to
 * the login page to enter the credentials.
 */
store.dispatch(Actions.SET_TOKEN).then(() => {
  store.dispatch(Actions.FETCH_USER).catch(() => {
    store.dispatch(Actions.CLEAR_AUTHENTICATION)
    router.replace({ name: 'sign-in' })
  })
}).catch(() => {
  store.dispatch(Actions.CLEAR_AUTHENTICATION)
})
app.use(store)
app.use(router)
// @ts-ignore
app.use(ElementPlus)

ApiService.init(app)
initApexCharts(app)
initInlineSvg(app)
initVeeValidate()

app.use(i18n)

app.mount('#app')
