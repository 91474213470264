import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'
import { Actions } from '@/store/enums/StoreEnums'
import localforage from 'localforage'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    meta: {
      guest: false,
      needsAuth: true
    },
    redirect: '/dashboard',
    component: () => import('@/layout/Layout.vue'),
    children: [
      {
        path: '/statistics/leads',
        name: 'LeadStatistics',
        meta: {
          guest: false,
          needsAuth: true
        },
        component: () => import('@/views/statistics/leads/LeadStatistics.vue')
      },
      {
        path: '/statistics/users',
        name: 'UsersStatistics',
        meta: {
          guest: false,
          needsAuth: true
        },
        component: () => import('@/views/statistics/users/UserStatistics.vue'),
        props: (route) => ({
          filter: route.query.userUuids ? String(route.query.userUuids).split(',') : null
        })
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
          guest: false,
          needsAuth: true
        },
        component: () => import('@/views/Dashboard.vue')
      },
      {
        path: '/accounts',
        name: 'accounts',
        meta: {
          guest: false,
          needsAuth: true
        },
        component: () => import('@/views/crafted/mc-superadmin/Accounts.vue')
      },
      {
        path: '/accounts/create',
        name: 'addAccount',
        meta: {
          guest: false,
          needsAuth: true
        },
        component: () => import('@/views/crafted/mc-superadmin/Accounts/AddAccount.vue')
      },
      {
        path: '/accounts/edit/:id',
        name: 'editAccount',
        meta: {
          guest: false,
          needsAuth: true
        },
        component: () => import('@/views/crafted/mc-superadmin/Accounts/EditAccount.vue')
      },
      {
        path: '/companies',
        name: 'companies',
        meta: {
          guest: false,
          needsAuth: true
        },
        component: () => import('@/views/crafted/mc-superadmin/Companies.vue')
      },
      {
        path: '/companies/create',
        name: 'addCompany',
        meta: {
          guest: false,
          needsAuth: true
        },
        component: () => import('@/views/crafted/mc-superadmin/Companies/AddCompany.vue')
      },
      {
        path: '/companies/edit/:id',
        name: 'editCompany',
        meta: {
          guest: false,
          needsAuth: true
        },
        component: () => import('@/views/crafted/mc-superadmin/Companies/EditCompany.vue')
      },
      {
        path: '/companies/overview/:id',
        name: 'overviewCompany',
        meta: {
          guest: false,
          needsAuth: true
        },
        component: () => import('@/views/crafted/mc-superadmin/Companies/OverviewCompany.vue')
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/components/page-layouts/Auth.vue'),
    children: [
      {
        path: '/sign-in',
        name: 'sign-in',
        meta: {
          guest: true,
          needsAuth: false
        },
        component: () =>
          import('@/views/crafted/authentication/basic-flow/SignIn.vue')
      },
      {
        path: '/sign-up',
        name: 'sign-up',
        component: () =>
          import('@/views/crafted/authentication/basic-flow/SignUp.vue')
      },
      {
        path: '/password-reset',
        name: 'password-reset',
        meta: {
          guest: true,
          needsAuth: false
        },
        component: () =>
          import('@/views/crafted/authentication/basic-flow/PasswordReset.vue')
      },
      {
        path: '/account-activation',
        name: 'account-activation',
        meta: {
          guest: true,
          needsAuth: false
        },
        component: () =>
          import('@/views/crafted/authentication/basic-flow/AccountActivation.vue')
      },
      {
        path: '/activation/hash/:id',
        name: 'activation',
        meta: {
          guest: true,
          needsAuth: false
        },
        component: () => import('@/views/crafted/authentication/basic-flow/SetPassword.vue')
      }
    ]
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    meta: {
      guest: true,
      needsAuth: false
    },
    component: () => import('@/views/crafted/authentication/Error404.vue')
  },
  {
    // the 404 route, when none of the above matches
    path: '/invalid-link',
    name: 'invalid-link',
    meta: {
      guest: true,
      needsAuth: false
    },
    component: () => import('@/views/crafted/authentication/basic-flow/InvalidLink.vue')
  },
  // {
  //   // the 404 route, when none of the above matches
  //   path: '/builder',
  //   name: 'builder',
  //   component: () => import('@/views/crafted/authentication/basic-flow/Builder.vue')
  // },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch(Actions.CHECK_TOKEN_EXISTS).then(() => {
    console.log('then ', to)
    if (to.meta.guest) {
      next({ name: 'sign-in' })
      return
    }
    next()
  }).catch(() => {
    console.log('catch', to.meta)
    if (to.meta.needsAuth) {
      localforage.setItem('intended', to.name)
      next({ name: 'sign-in' })
      return
    }
    next()
  })
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 100)
})

export default router
